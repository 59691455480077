@import url(https://fonts.googleapis.com/css?family=Martel+Sans&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body,
html,
#root {
  width: 100%;
  height: 100%;
  font-family: "Martel Sans", sans-serif;
  font-weight: 400;
  color: white;
}

#map {
  width: 100%;
  height: 100%;
  position: relative;
}

.tooltip {
  position: absolute;
  padding: 10px;
  font-size: 12px;
  background: black;
  border: 0px;
  border-radius: 3px;
  pointer-events: none;
  z-index: 9999;
  opacity: 0;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}

.tooltip span {
  font-weight: bolder;
}

/* Let's get this party started */
::-webkit-scrollbar {
  width: 12px;
}

/* Track */
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.25);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}
::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(255, 255, 255, 0.25);
}

.ol-overlaycontainer-stopevent {
  position: absolute;
  z-index: 9999;
  bottom: 12px;
  left: 800px;
}

.ol-overlaycontainer-stopevent .ol-zoom button {
  background: #23232e;
  border: none;
  font-size: 16px;
  padding: 3px 8px 3px 8px;
  border-radius: 3px;
  box-sizing: border-box;
  margin-right: 5px;
  cursor: pointer;
  color: rgb(255, 255, 255, 0.5);
}

.ol-scale-line-inner {
  color: rgb(255, 255, 255, 0.5);
}

.ol-rotate {
  display: none;
}
.ol-scale-line-inner {
  font-size: 10px;
  margin-top: 5px;
}

button:focus,
select {
  outline: 0;
}


.ol-tooltip {
  position: relative;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  color: white;
  padding: 4px 8px;
  opacity: 0.7;
  white-space: nowrap;
  font-size: 12px;
}

.ol-tooltip-layer-hover {
  left:-800px !important;
  opacity: 1;
  font-weight: bold;
}


.ol-tooltip-layer-hover:before {
  border-top: 6px solid rgba(0, 0, 0, 0.5);
  border-right: 6px solid transparent;
  border-left: 6px solid transparent;
  content: "";
  position: absolute;
  bottom: -6px;
  margin-left: -7px;
  left: 50%;
}


